@import './../shared-variables.scss';

html {
  overflow-y: scroll;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: $font-family;
  word-break: break-word;
}

body.p-overflow-hidden {
  padding-right: 0px !important;
}

:root {
  --font-family: $font-family;
}

.primary-box {
  background-color: var(--box-background-color);
  border-radius: $border-radius-lg;
  padding: 1.5rem;
}

.box-bg {
  background-color: var(--box-background-color);
}

.text-muted {
  color: var(--input-placeholder-color);
}

// primeng

.p-inputtext {
  border-radius: $input-border-radius;
}

.p-button:not(.p-button-outlined) {
  border: none;
}

.p-button:not(.p-button-rounded) {
  border-radius: $input-border-radius;
}

.p-button.p-button-outlined {
  border-width: 2px;
}

.p-button:not(.p-button-secondary):not(.p-button-success):not(
    .p-button-info
  ):not(.p-button-warning):not(.p-button-help):not(.p-button-danger):not(
    .p-button-plain
  ):not(.p-button-text):not(.p-fileupload-choose):not(.p-dialog-header-close) {
  background: linear-gradient(
    90deg,
    var(--brand-gradient-color-1),
    var(--brand-gradient-color-2)
  );
  border: none;
  color: $text-color;
  &:hover {
    filter: brightness(1.1);
    cursor: pointer;
  }
}

.p-button.p-button-text:not(.p-button-secondary):not(.p-button-success):not(
    .p-button-info
  ):not(.p-button-warning):not(.p-button-help):not(.p-button-danger):not(
    .p-button-plain
  ) {
  background: -webkit-linear-gradient(
    0deg,
    var(--brand-gradient-color-1),
    var(--brand-gradient-color-2)
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  &:hover {
    filter: brightness(1.1);
    cursor: pointer;
  }
}

.p-button.p-button-outlined:not(.p-button-rouneded):not(.p-button-icon-only) {
  border-radius: $input-border-radius;
}

.p-button.p-button-outlined:not(.p-button-secondary):not(.p-button-success):not(
    .p-button-info
  ):not(.p-button-warning):not(.p-button-help):not(.p-button-danger):not(
    .p-button-plain
  ):not(.p-button-icon-only):not(.p-dialog-header-close) {
  position: relative;

  color: var(--text-color);
  text-align: center;
  font-weight: normal;
  background: transparent;
  &:before {
    content: '';
    border-radius: $input-border-radius;
    position: absolute;
    inset: 0;
    padding: 2px;
    background: linear-gradient(
      90deg,
      var(--brand-gradient-color-1),
      var(--brand-gradient-color-2)
    );
    -webkit-mask: none;
    mask:
      none,
      linear-gradient(
          90deg,
          var(--brand-gradient-color-1),
          var(--brand-gradient-color-2)
        )
        content-box,
      linear-gradient(
        90deg,
        var(--brand-gradient-color-1),
        var(--brand-gradient-color-2)
      );
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
  &:hover {
    filter: brightness(1.25);
    cursor: pointer;
  }

  .p-button-label {
    // font-weight: bold;
    color: white;
  }
}

.p-button.p-button-outlined.p-button-icon-only:not(.p-button-secondary):not(
    .p-button-success
  ):not(.p-button-info):not(.p-button-warning):not(.p-button-help):not(
    .p-button-danger
  ):not(.p-button-plain) {
  position: relative;
  left: 4px;
  color: var(--text-color);
  text-align: center;
  font-weight: normal;
  background: transparent;
  &:before {
    content: '';
    border-radius: 2rem;
    position: absolute;
    inset: 0;
    padding: 2px;
    background: linear-gradient(
      90deg,
      var(--brand-gradient-color-1),
      var(--brand-gradient-color-2)
    );
    -webkit-mask: none;
    mask:
      none,
      linear-gradient(
          90deg,
          var(--brand-gradient-color-1),
          var(--brand-gradient-color-2)
        )
        content-box,
      linear-gradient(
        90deg,
        var(--brand-gradient-color-1),
        var(--brand-gradient-color-2)
      );
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
  &:hover {
    filter: brightness(1.25);
    cursor: pointer;
  }

  .p-button-label {
    font-weight: bold;
    color: white;
  }
}

.p-button.p-button-secondary:not(.p-button-outlined):not(.p-button-text),
.p-button.p-fileupload-choose {
  background: white;
  color: black;
}

.p-button-text.p-button-secondary {
  color: white;
}

.p-button.p-button-outlined.p-button-secondary {
  border-color: white;
  color: white;
}

.p-card {
  border-radius: $input-border-radius;
  .p-card-header img {
    border-top-left-radius: $input-border-radius;
    border-top-right-radius: $input-border-radius;
  }
}

.p-dropdown {
  border-radius: $input-border-radius;
}

.horizontal-line {
  height: 2px;
  border-radius: 12px;
  background: linear-gradient(
    90deg,
    var(--brand-gradient-color-1),
    var(--brand-gradient-color-2)
  );
}
