@mixin theme-colors(
  $background,
  $overlay,
  $text,
  $brand-gradient-1,
  $brand-gradient-2,
  $box-background,
  $input-background,
  $input-text,
  $input-placeholder,
  $input-border,
  $error
) {
  --background-color: #{$background};
  --overlay-color: #{$overlay};
  --text-color: #{$text};
  --brand-gradient-color-1: #{$brand-gradient-1};
  --brand-gradient-color-2: #{$brand-gradient-2};
  --box-background-color: #{$box-background};
  --input-background-color: #{$input-background};
  --input-text-color: #{$input-text};
  --input-placeholder-color: #{$input-placeholder};
  --input-border-color: #{$input-border};
  --error-color: #{$error};
}
