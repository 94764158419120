@import 'variables';
@import 'mixins';
@import 'base';
@import 'primeng/resources/themes/arya-blue/theme.css';

:root {
  @include theme-colors(
    $background-color,
    $overlay-color,
    $text-color,
    $brand-gradient-color-1,
    $brand-gradient-color-2,
    $box-background-color,
    $input-background-color,
    $input-text-color,
    $input-placeholder-color,
    $input-border-color,
    $error-color
  );
}
