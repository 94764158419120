@use '@ngneat/hot-toast/src/styles/styles.scss';

/* You can add global styles to this file, and also import other style files */
@layer tw-base, primeng, tw-components, tw-utilities, tw-variants;

// Please take note of the order in which these are imported
@import 'tailwindcss/base.css' layer(tw-base);
@import 'primeng/resources/primeng.min.css';
@import 'primeicons/primeicons';
@import 'tailwindcss/components.css' layer(tw-components);
@import 'tailwindcss/utilities.css' layer(tw-utilities);
@import 'tailwindcss/variants.css' layer(tw-variants);

@layer tw-base {
  * {
    @apply transition-all duration-300 ease-in-out;
  }
}

@layer tw-components {
  // tailwind component classes here
}

@layer tw-utilities {
  // tailwind utitlity classes here
}

@font-face {
  font-family: 'Poppins';
  font-weight: normal;
  font-style: normal;
  src: url('./assets/fonts/Poppins-Regular.ttf');
}

@font-face {
  font-family: 'Poppins';
  font-weight: bold;
  font-style: normal;
  src: url('./assets/fonts/Poppins-Bold.ttf');
}

@font-face {
  font-family: 'Poppins';
  font-weight: normal;
  font-style: italic;
  src: url('./assets/fonts/Poppins-Italic.ttf');
}

@font-face {
  font-family: 'Poppins';
  font-weight: bold;
  font-style: italic;
  src: url('./assets/fonts/Poppins-BoldItalic.ttf');
}
