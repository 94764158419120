// define theme independent variables
// general
$font-family: 'Poppins', sans-serif;

// inputs
$input-border-radius: 12px;

// border
$border-radius-sm: 8px;
$border-radius-md: 12px;
$border-radius-lg: 24px;
