// Define default theme variables
$background-color: #000000;
$overlay-color: #18181b;
$text-color: #ffffff;
$brand-gradient-color-1: #3663d6;
$brand-gradient-color-2: #c44f71;
$box-background-color: #1e1e1e;
$input-background-color: #111315;
$input-text-color: #ffffff;
$input-placeholder-color: #bec2cc;
$input-border-color: #9da3af;
$error-color: #ff4d4f;
